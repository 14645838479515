import { GOVERNING_BODIES, WS_ROUNDS_TYPES } from "constants/types";
import {
  actionFulfilled,
  CHECK_AAU_MEMBERSHIP,
  UPDATE_ROUNDS,
} from "store/actionTypes";

import { store } from "App";
import { createWebsocket } from "API/websockets";
import { getEventRoundsListRequest } from "API/rounds";
import { checkAauMembershipRequest } from "API/membership";
import {
  updateRounds,
  updateJudges,
  setFocusedDive,
  updateDiverDives,
  updateDiverDive,
} from "store/actions/rounds";
import { errorCallback } from "helpers/general";

const ATTEMPT_NUMBER_INIT = 1;
const NUMBER_OF_ATTEMPTS = 3;

export const getEventInfo = (props = {}) => {
  const { judgePanel = {}, rounds = [] } = props;

  rounds
    ?.sort(
      (
        { number: a, subRounds: subRoundsA },
        { number: b, subRounds: subRoundsB },
      ) => (a || subRoundsA.number) - (b || subRoundsB.number),
    )
    .unshift("");
  delete rounds[0];

  return {
    judges: judgePanel,
    rounds: { ...rounds },
  };
};

export const updateEventInfo = ({ judgePanel, rounds }) => {
  const response = getEventInfo({ judgePanel, rounds });

  store.dispatch(updateRounds(response));
  store.dispatch(updateJudges(response));
};

const checkAauMembership = ({
  info,
  response,
  attemptNumber = ATTEMPT_NUMBER_INIT,
}) => {
  checkAauMembershipRequest(
    {
      info,
    },
    (error) => {
      if (attemptNumber > NUMBER_OF_ATTEMPTS) {
        errorCallback(error);

        return;
      }

      checkAauMembership({
        info,
        response,
        attemptNumber: attemptNumber + 1,
      });
    },
  ).then((result) => {
    store.dispatch({
      type: actionFulfilled(CHECK_AAU_MEMBERSHIP),
      payload: result.reduce(
        (acc, { userId, isDiver, ...aauUserInfoDTO }) => ({
          ...acc,
          [userId]: {
            ...(acc[userId] || {}),
            [isDiver ? "diver" : "coach"]: aauUserInfoDTO,
          },
        }),
        {},
      ),
    });
    store.dispatch({
      type: UPDATE_ROUNDS,
      payload: response,
    });
  });
};

export const createWebsocketAdmin =
  ({ eventId, governingBody }) =>
  () => {
    if (!eventId || !governingBody) return;

    getEventRoundsListRequest(eventId).then((response) => {
      const { rounds = {} } = response;

      if (
        ![GOVERNING_BODIES.AAU, GOVERNING_BODIES.JCDC].includes(governingBody)
      )
        return store.dispatch({
          type: UPDATE_ROUNDS,
          payload: response,
        });

      const { dives = [], subRounds = [] } = rounds[1];
      const allDives = [
        ...(dives || []),
        ...subRounds.flatMap(({ dives }) => dives || []),
      ].filter((dives) => dives);
      const info = allDives
        .map(({ user, coach, combinedUser }) => {
          const users = combinedUser
            ? combinedUser.users
            : [{ ...user, coach }];

          return users.flatMap(({ id, coach }) => [
            { userId: id, isDiver: true },
            {
              userId: coach?.id,
              isDiver: false,
            },
          ]);
        })
        .flat(1)
        .filter(({ userId }) => userId);

      if (!info.length)
        return store.dispatch({
          type: UPDATE_ROUNDS,
          payload: response,
        });

      checkAauMembership({ info, response });
    });

    const onMessage = (event) => {
      const message = JSON.parse(event.data);
      const { type } = message;

      switch (type) {
        case WS_ROUNDS_TYPES.SHEET: {
          const { dives, roundStatuses } = message;
          store.dispatch(
            updateDiverDives({
              dives,
              roundStatuses,
            }),
          );

          break;
        }
        case WS_ROUNDS_TYPES.FOCUS: {
          const { newFocus, roundStatuses } = message;
          store.dispatch(setFocusedDive({ newFocus, roundStatuses }));

          break;
        }
        case WS_ROUNDS_TYPES.DIVE: {
          const { data } = message;

          if (!data) break;

          store.dispatch(updateDiverDive({ dive: data }));

          break;
        }
        case WS_ROUNDS_TYPES.FULL:
        default: {
          updateEventInfo(message);
        }
      }
    };
    const ws = createWebsocket({
      path: `event/${eventId}/admin`,
      onMessage,
      isSpinnerHidden: true,
    });

    return () => {
      ws?.close(1000, "Close component");
    };
  };
