import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import breakpoint from "styled-components-breakpoint";

import silver from "assets/images/medals/silver.png";
import gold from "assets/images/medals/gold.png";
import bronze from "assets/images/medals/bronze.png";

import { ROUTE } from "constants/routing";
import { LEADERSHIP_MODES } from "constants/leadershipModes";

import DiverLabel from "Components/DiverLabel";
import HeaderForItem from "./HeaderForItem";
import Divider from "./Divider";

const StyledItemHolder = styled.div`
  padding: 0 20px;
`;
const StyledDiveInfo = styled.div`
  border: 1px solid black;
  padding: 15px;
  border-radius: 20px;
  text-align: center;
  cursor: pointer;
  & .placeInfo {
    font-weight: 600;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    white-space: break-spaces;
  }
  & .label {
    margin-bottom: 2px;
  }
  & .eventInfo {
    font-size: 18px;
  }
  & .score {
    font-weight: 600;
    line-height: 85px;
    font-size: 80px;

    ${breakpoint("sm")`
      font-size: 100px;
    `};
  }
`;
const StyledMedal = styled.div`
  width: 16px;
  margin-left: 9px;
  height: 26px;
  background-size: contain;
  background-repeat: no-repeat;
  ${({ placedAt }) => placedAt > 3 && "display: none;"}
  background-image: url(${({ placedAt }) => {
    switch (placedAt) {
      case 1:
        return gold;
      case 2:
        return silver;
      case 3:
        return bronze;
      default:
        return "";
    }
  }});
`;

const ResultFeedItem = ({ resultInfo }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const {
    place,
    exhibition,
    prequalified,
    totalResult,
    userInfo,
    meetName,
    createdDate,
    diveSheetId,
    eventName,
    eventId,
    stage,
    combinedUserInfo,
  } = resultInfo?.diversResults || {};

  const onItemBodyClick = () => {
    const diverId = combinedUserInfo?.id || userInfo?.id;

    history.push(
      ROUTE.LEADERSHIP(
        eventId,
        LEADERSHIP_MODES.diveList,
        `${diverId}-${diveSheetId}`,
      ),
    );
  };

  const getPlaceEnding = (place) => {
    if (place >= 4 && place <= 20) return t("th");

    const lastNumber = place % 10;

    if (place === 1 || lastNumber === 1) return t("st");
    if (place === 2 || lastNumber === 2) return t("nd");
    if (place === 3 || lastNumber === 3) return t("rd");
    if (place === 3 || lastNumber === 3) return t("rd");
    if ((lastNumber >= 4 && lastNumber <= 9) || lastNumber === 0)
      return t("th");

    return "";
  };

  return (
    <>
      <StyledItemHolder>
        <HeaderForItem
          uploadedAt={createdDate || resultInfo.date}
          combinedUserInfo={combinedUserInfo}
          user={userInfo}
        />
        <StyledDiveInfo onClick={onItemBodyClick}>
          <p className="placeInfo">
            {exhibition || prequalified ? (
              <>
                {exhibition ? "Exhibition " : "Prequalified "}
                <DiverLabel className="label">
                  {exhibition ? "Ex" : "Pre"}
                </DiverLabel>
              </>
            ) : (
              <>
                {`${"Placed at"} ${place}${getPlaceEnding(place)}`}
                <StyledMedal placedAt={place} />
              </>
            )}
          </p>
          <p className="eventInfo">{[eventName, stage].join(" ")}</p>
          <p className="eventInfo">{meetName && `${t("at")} ${meetName}`}</p>
          <p className="score">{totalResult.toFixed(2)}</p>
        </StyledDiveInfo>
      </StyledItemHolder>
      <Divider />
    </>
  );
};

export default ResultFeedItem;
