import AAU_LOGO from "assets/images/governingBodies/base64/aau";
import AUSTRALIA_LOGO from "assets/images/governingBodies/base64/australia";
import DPC_LOGO from "assets/images/governingBodies/base64/dpc";
import YMCA_LOGO from "assets/images/governingBodies/base64/ymca";
import FINA_LOGO from "assets/images/governingBodies/base64/fina";
import NCAA_LOGO from "assets/images/governingBodies/base64/ncaa";
import NFHS_LOGO from "assets/images/governingBodies/base64/nfhs";
import USAD_LOGO from "assets/images/governingBodies/base64/usad";
import NAIA_LOGO from "assets/images/governingBodies/base64/naia";
import NJCAA_LOGO from "assets/images/governingBodies/base64/njcaa";
import MCDL_LOGO from "assets/images/governingBodies/base64/mcdl";
import CMDL_LOGO from "assets/images/governingBodies/base64/cmdl";
import RCSL_LOGO from "assets/images/governingBodies/base64/rcsl";
import RMDL_LOGO from "assets/images/governingBodies/base64/rmdl";
import GMAC_LOGO from "assets/images/governingBodies/base64/gmac";
import JCDC_LOGO from "assets/images/governingBodies/base64/jcdc";
import ESF_LOGO from "assets/images/governingBodies/base64/esf";
import SUNKIST_LOGO from "assets/images/governingBodies/base64/sunkist";

import {
  MEET_STEPS,
  GOVERNING_BODIES as GOVERNING_BODIES_TYPES,
} from "constants/types";
import { GCP_BUCKET_URL_GB } from "constants/env";
import { PAYMENT_KEYS } from "constants/createEditMeet";

const {
  CHOOSE_TYPE,
  GOVERNING_BODIES,
  INVITE_TEAMS,
  MEETING_FORM_EDIT,
  MEETING_FORM,
  MEETING_DATE,
  CHOOSE_FEES,
  SET_FEES,
  PAYMENT_METHOD,
  ORDER_EVENTS,
  EVENTS,
  EVENT_EDIT,
  INVITE_JUDGES,
  LETS_REVIEW,
  SET_TIME,
} = MEET_STEPS;
const {
  FINA,
  NCAA,
  AAU,
  AUSTRALIA,
  NFHS,
  USAD,
  DPC,
  YMCA,
  NAIA,
  NJCAA,
  MCDL,
  CMDL,
  RCSL,
  RMDL,
  GMAC,
  SUNKIST,
  ESF,
  JCDC,
} = GOVERNING_BODIES_TYPES;

export const swipeDays = (days, sourceEventId, newDay) => {
  const newDayIndex = days.findIndex(({ id }) => id === sourceEventId);

  const newDays = [
    ...days.slice(0, newDayIndex),
    newDay,
    ...days.slice(newDayIndex + 1),
  ];

  return newDays;
};

export const meetFlatSubEvents = (events) =>
  events
    ?.filter((event) => !!event)
    ?.sort(({ eventOrder: a }, { eventOrder: b }) => a - b)
    ?.map((event) => {
      const { subEvents = [] } = event;

      subEvents.sort(({ eventOrder: a }, { eventOrder: b }) => b - a);

      return subEvents.length ? [event, ...subEvents] : event;
    })
    ?.flat();

export const swipeSessions = (sourceSessionId, sessions, newSession) => {
  const newSessionIndex = sessions.findIndex(
    ({ id }) => id === sourceSessionId,
  );

  const newSessions = [
    ...sessions.slice(0, newSessionIndex),
    newSession,
    ...sessions.slice(newSessionIndex + 1),
  ];

  return newSessions;
};

export const getMeetHeaderText = (step, t) => {
  switch (step) {
    case CHOOSE_TYPE:
    case MEETING_FORM:
      return t("Meet info");
    case MEETING_FORM_EDIT:
      return t("Edit meet");
    case GOVERNING_BODIES:
      return t("Governing bodies");
    case INVITE_TEAMS:
      return t("Teams");
    case MEETING_DATE:
      return t("Dates");
    case CHOOSE_FEES:
      return t("Choose fees");
    case SET_FEES:
      return t("Set fees");
    case PAYMENT_METHOD:
      return t("Payment method");
    case EVENTS:
    case EVENT_EDIT:
      return t("Event set up");
    case ORDER_EVENTS:
      return t("Order events");
    case INVITE_JUDGES:
      return t("Judges");
    case LETS_REVIEW:
      return t("Review");
    case SET_TIME:
      return t("Times");
    default:
      return "";
  }
};

export const replaceEventOrSubEventInMeet = (
  oldMeet,
  newEvent,
  { dayId, sessionId },
) => ({
  ...oldMeet,
  days: oldMeet.days
    .map((day) =>
      day.id === dayId
        ? {
            ...day,
            sessions: day.sessions
              .map((session) =>
                session.id === sessionId
                  ? {
                      ...session,
                      events: session.events.map((event) =>
                        event.id === newEvent.id
                          ? { ...newEvent }
                          : {
                              ...event,
                              subEvents: event.subEvents?.map((subEvent) =>
                                subEvent?.id === newEvent.id
                                  ? { ...newEvent }
                                  : { ...subEvent },
                              ),
                            },
                      ),
                    }
                  : { ...session },
              )
              .map((session) => ({
                ...session,
                warmUp: {
                  startTime: session.warmUp.startTime
                    ? { ...session.warmUp.startTime, nano: 0, second: 0 }
                    : session.warmUp.startTime,
                  endTime: session.warmUp.endTime
                    ? { ...session.warmUp.endTime, nano: 0, second: 0 }
                    : session.warmUp.endTime,
                },
              })),
          }
        : { ...day },
    )
    .map(({ sessions, ...day }) => ({
      ...day,
      sessions: sessions.map(({ events, ...session }) => ({
        ...session,
        events: events.map(({ subEvents = [], ...event }) => ({
          ...event,
          diveSheets: [],
          subEvents: subEvents.map((subEvent) => ({
            ...subEvent,
            diveSheets: [],
          })),
        })),
      })),
    })),
});

export const getGoverningBodyLogo = (abbreviation) =>
  `${GCP_BUCKET_URL_GB}${abbreviation || NFHS}.png`;

export const getGoverningBodyLogoBase64 = (abbreviation) => {
  switch (abbreviation) {
    case DPC:
      return DPC_LOGO;
    case YMCA:
      return YMCA_LOGO;
    case FINA:
      return FINA_LOGO;
    case NCAA:
      return NCAA_LOGO;
    case NFHS:
      return NFHS_LOGO;
    case USAD:
      return USAD_LOGO;
    case AAU:
      return AAU_LOGO;
    case AUSTRALIA:
      return AUSTRALIA_LOGO;
    case NAIA:
      return NAIA_LOGO;
    case NJCAA:
      return NJCAA_LOGO;
    case MCDL:
      return MCDL_LOGO;
    case SUNKIST:
      return SUNKIST_LOGO;
    case CMDL:
      return CMDL_LOGO;
    case RCSL:
      return RCSL_LOGO;
    case RMDL:
      return RMDL_LOGO;
    case GMAC:
      return GMAC_LOGO;
    case JCDC:
      return JCDC_LOGO;
    case ESF:
      return ESF_LOGO;
    default:
      return NFHS_LOGO;
  }
};

export const getRuleName = (abbreviation) => {
  switch (abbreviation) {
    case FINA:
      return "World Aquatics";
    case AAU:
      return "Amateur Athletic Union Diving";
    case AUSTRALIA:
      return "Diving Australia";
    case NCAA:
      return "National Collegiate Athletic Association";
    case NFHS:
      return "National Federation of High School Sports";
    case DPC:
      return "Diving Plongeon Canada";
    case YMCA:
      return "YMCA";
    case NAIA:
      return "National Association of Intercollegiate Athletics";
    case NJCAA:
      return "National Junior College Athletic Association";
    //   Get via API and new table dive_team_governing_body_lv to have translatable
    case CMDL:
      return "Central Maryland Diving League";
    case MCDL:
      return "Montgomery County Diving League";
    case RCSL:
      return "Rocket City Summer League";
    case RMDL:
      return "Rocky Mountain Dive League";
    case GMAC:
      return "Greater Mansfield Aquatic Conference";
    case JCDC:
      return "Jefferson County Diving Council";
    case SUNKIST:
      return "Sunkist League";
    case ESF:
      return "Egyptian Swimming Federation";
    default:
      return "";
  }
};

export const getNewSession = (sessionNumber = 1) => ({
  id: Math.floor(-Date.now() * Math.random()),
  sessionNumber,
  warmUp: { startTime: null, endTime: null },
  events: [],
});

export const getPaymentDataForRequest = (config, values) => {
  const currentPaymentKey = PAYMENT_KEYS[values.paymentMethod];
  const { inputs, radioName } = config.find(
    (item) => item.radioName === values.paymentMethod,
  );

  return inputs.reduce(
    (acc, cur) => {
      return {
        ...acc,
        [currentPaymentKey]: {
          ...acc[currentPaymentKey],
          [cur.name]: values[cur.name] || undefined,
        },
      };
    },
    { paymentMethodType: radioName, [currentPaymentKey]: {} },
  );
};

export const getFixedPrice = ({ price, currency }) =>
  `${currency?.symbol_left}${Number(price).toFixed(2)}${
    currency?.symbol_right
  }`;
