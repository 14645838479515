import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { ROUTE } from "constants/routing";
import { LIVE_STATUS_EVENT } from "constants/types";
import { LEADERSHIP_MODES } from "constants/leadershipModes";

import diveLiveGif from "assets/images/diveLiveGif.gif";
import diveLiveWithoutBlinking from "assets/images/diveLiveWithoutBlinking.gif";

import { getEventLiveStatus } from "API/rounds";
import { getLocalStorage, setLocalStorage } from "helpers/general";

const LIVE_STATUS = "Live";

const StyledButton = styled.button`
  flex-shrink: 0;
  width: 80px;
  height: 25px;
  padding: 0;
  border: none;
  background: url(${({ withBlinking }) =>
    withBlinking ? diveLiveGif : diveLiveWithoutBlinking});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom;
  cursor: pointer;
`;

const DiveLiveBtn = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const [liveEventId, setLiveEventId] = useState("");

  const onDiveLiveClick = () => {
    getEventLiveStatus().then(({ eventId, status }) => {
      if (eventId && status === LIVE_STATUS) {
        setLocalStorage({ key: LIVE_STATUS_EVENT, value: eventId });
        history.push(ROUTE.LEADERSHIP(eventId, LEADERSHIP_MODES.currentRound));

        return;
      }

      setLiveEventId("");
      toast.error(t("Please find a meet using the search tool"));
    });
  };

  useEffect(() => {
    const liveStatusEvent = getLocalStorage(LIVE_STATUS_EVENT);

    if (liveStatusEvent) {
      setLiveEventId(liveStatusEvent);
    }
  }, []);

  return (
    <StyledButton
      withBlinking={!!liveEventId}
      onClick={onDiveLiveClick}
      aria-label="get live event"
    />
  );
};

export default DiveLiveBtn;
