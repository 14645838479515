import React from "react";
import styled from "styled-components";

const StyledH4 = styled.p`
  font-size: 12px;
  font-weight: 600;
  color: ${({ theme }) => theme.basicTextColor};
  text-align: right;
`;

const StyledSpan = styled.span`
  color: #ffec00;
`;

const BestScores = ({ scoreMap }) => {
  const scoreKeys = Object.keys(scoreMap || {});
  const HEIGHTS = [
    { id: 1, value: "ONE", title: "1M" },
    { id: 2, value: "THREE", title: "3M" },
    { id: 3, value: "PLATFORM", title: "PLATFORM" },
    { id: 4, value: "MIXED", title: "MIXED" },
  ];

  return (
    <React.Fragment>
      {HEIGHTS.filter(({ value }) => scoreKeys.includes(value)).map(
        ({ id, title, value }) => (
          <StyledH4 key={id}>
            {`${title}: `}
            <StyledSpan>{scoreMap[value]}</StyledSpan>
          </StyledH4>
        ),
      )}
    </React.Fragment>
  );
};

export default BestScores;
