export const AVATAR_TYPE = {
  USER: "USER",
  TEAM: "TEAM",
  BACKGROUND: "BACKGROUND",
  DELETED: "DELETED",
};

export const MEET_TYPES = {
  INVITATIONAL: "Invitational",
  CHAMPIONSHIP: "Championship",
  DUAL: "Dual",
  MOCK: "Mock",
};

export const ROUND_TYPE_LABELS = {
  PRELIMINARY: "Preliminary",
  QUARTER_FINALS: "Quarter-finals",
  SEMI_FINALS: "Semi-finals",
  FINALS: "Finals",
};

export const GOVERNING_BODIES = {
  USAD: "USAD",
  FINA: "FINA",
  NFHS: "NFHS",
  NCAA: "NCAA",
  AUSTRALIA: "AUSTRALIA",
  AAU: "AAU",
  DPC: "DPC",
  YMCA: "YMCA",
  NAIA: "NAIA",
  NJCAA: "NJCAA",
  MCDL: "MCDL",
  CMDL: "CMDL",
  RMDL: "RMDL",
  GMAC: "GMAC",
  ESF: "ESF",
  JCDC: "JCDC",
  SUNKIST: "SUNKIST",
};

export const ROLES = {
  DIVER: "DIVER",
  HEAD_COACH: "HEAD_DIVING_COACH",
  PARENT: "PARENT",
  COACH: "DIVING_COACH",
  TEAM_ADMIN: "TEAM_ADMIN",
  SWIM_COACH: "SWIMMING_COACH",
  ALUMNUS: "ALUMNUS",
  SWIMMER: "SWIMMER",
  JUDGE: "JUDGE",
};

export const MEET_STEPS = {
  CHOOSE_TYPE: "chooseType",
  GOVERNING_BODIES: "selectGoverningBodies",
  INVITE_TEAMS: "inviteTeams",
  MEETING_FORM: "meetingForm",
  MEETING_FORM_EDIT: "editMeetInfo",
  MEETING_DATE: "meetingDate",
  SET_TIME: "setTime",
  CHOOSE_FEES: "chooseFees",
  SET_FEES: "setFees",
  PAYMENT_METHOD: "paymentMethod",
  ORDER_EVENTS: "orderEvents",
  EVENTS: "events",
  EVENT_CREATE: "createEvent",
  EVENT_EDIT: "editEvents",
  INVITE_JUDGES: "inviteJudges",
  LETS_REVIEW: "letsReview",
};

export const STEPS_DIRECTION = [
  MEET_STEPS.CHOOSE_TYPE,
  MEET_STEPS.GOVERNING_BODIES,
  MEET_STEPS.INVITE_TEAMS,
  MEET_STEPS.MEETING_FORM,
  MEET_STEPS.MEETING_DATE,
  MEET_STEPS.SET_TIME,
  MEET_STEPS.ORDER_EVENTS,
  MEET_STEPS.EVENTS,
  MEET_STEPS.INVITE_JUDGES,
  MEET_STEPS.LETS_REVIEW,
];

export const STEPS_WITH_FEES_DIRECTION = [
  MEET_STEPS.CHOOSE_TYPE,
  MEET_STEPS.GOVERNING_BODIES,
  MEET_STEPS.INVITE_TEAMS,
  MEET_STEPS.MEETING_FORM,
  MEET_STEPS.MEETING_DATE,
  MEET_STEPS.SET_TIME,
  MEET_STEPS.CHOOSE_FEES,
  MEET_STEPS.SET_FEES,
  MEET_STEPS.PAYMENT_METHOD,
  MEET_STEPS.ORDER_EVENTS,
  MEET_STEPS.EVENTS,
  MEET_STEPS.INVITE_JUDGES,
  MEET_STEPS.LETS_REVIEW,
];

export const GENDER_KEYS = {
  MALE: "male",
  FEMALE: "female",
  NOT_SPECIFIED: "not specified",
};

export const GENDER = {
  MALE: "Male",
  FEMALE: "Female",
  MIXED: "Any",
};

export const getYesNoModalDefaultState = (t) => ({
  leftBtnName: t("Yes"),
  rightBtnName: t("No"),
  onLeftBtnClick: (e) => {
    e.stopPropagation();
  },
  onRightBtnClick: (e) => {
    e.stopPropagation();
  },
});

export const WEBSOCKET_MSG_TYPES = {
  FOCUS_CHANGED: "FOCUS_CHANGED",
  EVENT_STARTED: "EVENT_STARTED",
  EVENT_CLOSED: "EVENT_CLOSED",
  EVENT_CANCELLED: "EVENT_CANCELLED",
};

export const ATTACHMENT_TYPES = {
  FILE: "file",
  IMAGE: "image",
  TEXT: "text",
  LINK: "link",
};

export const LIVE_STATUS_EVENT = "live_status_event_id";

export const NFHS_NUMBER_OF_DIVES_FOR_FINALS = 11;

export const OTHER_ELIGIBLE_EVENTS = "other";
export const MAIN_AVAILABLE_EVENTS = "available";

export const DIVER_CATEGORY = {
  NOVICE: "Novice",
  MASTERS: "Masters",
  SYNCHRO: "Synchro",
};

export const MEET_FEES = {
  FREE: "FREE",
  PAID: "PAID",
};

export const MEET_REVIEW_TABS = {
  MEET_DETAILS: "details",
  SCHEDULE: "schedule",
  JUDGES: "judges",
  TEAMS: "teams",
  TICKETS: "tickets",
};

export const MY_TEAM_TABS = {
  TEAM_MEMBERS: "members",
  INVITATIONS: "invitations",
};

export const PROFILE_USERS_TABS = {
  FOLLOWING: "following",
  FOLLOWERS: "followers",
};

export const SEARCH_MEET_TABS = {
  PAST: "past",
  TODAY: "current",
  FUTURE: "future",
};

export const SHEET_TYPES = {
  RECENT: "Recent",
  UPCOMING: "Upcoming",
};

export const MEETS_TYPES = {
  PAST: "Past",
  UPCOMING: "Upcoming",
};

export const BESTS_TYPES = {
  HIGH_SCHOOL: "High School",
  COLLEGE: "College",
  CLUB: "Club",
};

export const TICKET_CANVAS_TYPES = {
  DEFAULT: "default",
  HIGH_SCHOOL: "High School",
  COLLEGE: "College",
  CLUB: "Club",
  MEET: "Meet",
  ENDED_MEET: "Ended meet",
  FOOTER: "Footer",
};

export const CURRENCY_CONFIG = {
  USD: {
    min: 5,
    cutoff: 60,
    after: 0.09,
    coin: {
      singular: "cent",
      plural: "cents",
    },
  },
  CAD: {
    min: 6.5,
    cutoff: 65,
    after: 0.09,
    coin: {
      singular: "cent",
      plural: "cents",
    },
  },
  EUR: {
    min: 1,
    cutoff: 50,
    after: 0.05,

    coin: {
      singular: "cent",
      plural: "cents",
    },
  },
  GBP: {
    min: 1,
    cutoff: 50,
    after: 0.05,
    coin: {
      singular: "penny",
      plural: "pence",
    },
  },
  AUD: {
    min: 5,
    cutoff: 60,
    after: 0.09,
    coin: {
      singular: "cent",
      plural: "cents",
    },
  },
};

export const REPORT_TYPES = {
  NUDITY: "NUDITY",
  HATE: "HATE",
  SCAM: "SCAM",
  VIOLENCE: "VIOLENCE",
  SALE: "SALE",
  BULLING: "BULLING",
  INTELLECTUAL: "INTELLECTUAL",
  SUICIDE: "SUICIDE",
  SPAM: "SPAM",
  WRONG: "WRONG",
  OTHER: "OTHER",
};

export const JUDGE_TYPES = {
  JUDGE: "JUDGE",
  REFEREE: "REFEREE",
  ASSISTANT: "ASSISTANT",
};

export const WS_ROUNDS_TYPES = {
  SHEET: "SHEET",
  FOCUS: "FOCUS",
  DIVE: "DIVE",
  FULL: "FULL",
};
